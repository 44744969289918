<template>
  <div class="login">
    <div class="tit">
      <h3>找回密码</h3>
    </div>
    <el-form class="login-form level" ref="loginForm" :model="form" :rules="rules">
      <el-form-item prop="userName">
        <p class="label">
          <i class="iconfont iconshouji"></i>
          <label>用户名</label>
        </p>
        <el-input v-model="form.userName" placeholder="请输入手机号码或邮箱"></el-input>
      </el-form-item>
      <el-form-item prop="captcha">
        <p class="label">
          <i class="iconfont iconyanzhengma"></i>
          <label>图片验证</label>
        </p>
        <el-input v-model="form.captcha" placeholder="图片验证码"></el-input>
        <img style="height: 32px;width: 96px;border-radius: 4px;" alt="点击刷新" title="点击刷新"
             class="pointer" :src="codeUrl" @click="refreshCode">
      </el-form-item>
      <el-form-item prop="smsCode">
        <p class="label">
          <i class="iconfont iconduanxinma"></i>
          <label>验证码</label>
        </p>
        <el-input v-model="form.smsCode" placeholder="请输入短信或邮箱验证码"></el-input>
        <div class="code" @click="sendCode" v-if="!isSendCode">发送验证码</div>
        <div class="code" v-else>{{count}}S后重发</div>
      </el-form-item>
      <el-form-item prop="password">
        <p class="label">
          <i class="iconfont iconmima1"></i>
          <label>密码</label>
        </p>
        <el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item prop="password2">
        <p class="label">
          <i class="iconfont iconmima1"></i>
          <label>确认密码</label>
        </p>
        <el-input v-model="form.password2" type="password" placeholder="请再次输入密码"></el-input>
      </el-form-item>
   <!--   <el-form-item prop="verificationCode" class="item">
        <p class="label">
          <i class="iconfont iconyanzhengma"></i>
          <label>验证码</label>
        </p>
&lt;!&ndash;        <el-input v-model="form.verificationCode" placeholder="请输入验证码"></el-input>&ndash;&gt;
&lt;!&ndash;        <img class="img" src="@/assets/images/login/logo.png" alt />&ndash;&gt;
        <el-input v-model="form.verificationCode" placeholder="验证码输入到这里！"></el-input>
        <div class="codeImage" @click="refreshCode">
          <s-identify :identifyCode="identifyCode"></s-identify>
        </div>
      </el-form-item>-->
      <el-form :inline="true">
        <el-form-item class="btn">
          <a class="btn-black" @click="findPassword">找回</a>
        </el-form-item>
        <el-form-item class="btn">
          <a class="btn-border" @click="goBack">
            <i class="iconfont iconyonghu"></i>
            <span>返回登录</span>
          </a>
        </el-form-item>
      </el-form>
    </el-form>
  </div>
</template>

<script>
  import regexJs from "../../../utils/regex";
  import { resetPwd, getVerificationCode,checkCaptcha,getCaptcha} from "../../../api/auth";
  import SIdentify from '../../../components/identify/identify';
  import { message } from '@/utils/resetMessage';
export default {
  components:{
    SIdentify
  },
  computed: {
    updateSendCodeCountState() {
      return this.$store.state.common.sendCodeCount;
    }

  },
  data() {
    return {
      form: {
        userName: "",
        captcha:"",
        password: "",
        password2: "",
        smsCode: "",
        verificationCode: "",
        type: 1
      },
      src: window.baseUrl+'/api/auth/captcha.jpg',
      codeUrl:'',
      uuid:'',
      rules: {
        userName: [
          { required: true, message: "请输入手机号码或邮箱", trigger: "blur" }
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password2: [
          { required: true, message: "请再次输入密码", trigger: "blur" }
        ],
        smsCode: [{ required: true, message: "请输入短信或邮箱验证码", trigger: "blur" }],
        captcha: [{ required: true, message: "请输入图片验证码", trigger: "blur" }],
       /* verificationCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ]*/
      },
      identifyCodes: "1234567890",
      identifyCode: "",
      count: this.$store.state.common.sendCodeCount,//120
      isSendCode: this.$store.state.common.sendCodeCount!=120?true:false,
    };
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    this.refreshCode();
  },
  created() {
    if(this.count!=120){//定时未完成，启动定时器
      this.timeInterval = setInterval(() => {
        if (this.count <= 0) {
          clearInterval(this.timeInterval);
          this.isSendCode = false;
          this.count=120;
          this.$store.commit("updateSendCodeCountState",this.count);
        } else {
          this.count--;
          this.$store.commit("updateSendCodeCountState",this.count) /* 提交vuex状态改变 */
        }
      }, 1000);
    }
  },
  methods: {
    findPassword() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          //this.$router.push({ path: "/login" });
          if (
              !(
                  regexJs.telephone.test(this.form.userName) ||
                  regexJs.email.test(this.form.userName)
              )
          ) {
            return false;
          }
          if (this.form.password != this.form.password2) {
            // todo: 密码不一致提示
            message.error({
              message: '密码不一致',
              duration: 2000,
              offset: 80
            })
            return false;
          }
          this.judgeUserType();
          resetPwd(this.form).then(result => {
            if (result) {
              message.success({
                message: '重置密码成功',
                duration: 2000,
                offset: 80
              })
              this.$router.push({ path: "/login" });
            }
          }).catch(function(errorResult) {
            message.error({
              message: errorResult,
              duration: 2000,
              offset: 80
            })
            console.log("errorResult", errorResult);
          });
        } else {
          return false;
        }
      });
    },
    judgeUserType() {
      this.form.type = regexJs.email.test(this.form.userName)
              ? constants.loginNameType.email
              : constants.loginNameType.phone;
    },
    /*refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },*/
    refreshCode: function () {
      //this.src = window.baseUrl+"/api/auth/captcha.jpg?t=" + new Date().getTime();
      getCaptcha({}).then(res =>{
        this.codeUrl = res.img;
        this.uuid = res.uuid;
      }).catch(error =>{
        message.error({
            message: '获取验证码失败，请重试',
            duration: 2000,
            offset: 80
          })
      });
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    sendCode() {
      if (
              !this.form.userName ||!this.form.captcha||
              !(
                      regexJs.telephone.test(this.form.userName) ||
                      regexJs.email.test(this.form.userName)
              )
      ) {
        message.error({
          message: '用户名不正确',
          duration: 2000,
          offset: 80
        })
        return;
      }
      this.judgeUserType();
      let loading = this.$loading({
        type:'oval'
      })
      let captchaparam = {
        captcha: this.form.captcha,
        uuid:this.uuid
      };
      checkCaptcha(captchaparam).then(result => {
        //验证码正确才发送短信
        let param = {
          user: this.form.userName,
          type: this.form.type,
          sendCodeType:constants.sendCodeType.reset,
        };
        getVerificationCode(param)
                .then(result => {
                  loading.close();
                  this.isSendCode = true;
                  this.timeInterval = setInterval(() => {
                    if (this.count <= 0) {
                      clearInterval(this.timeInterval);
                      this.isSendCode = false;
                      this.count=120;
                      this.$store.commit("updateSendCodeCountState",this.count);
                    } else {
                      this.count--;
                      this.$store.commit("updateSendCodeCountState",this.count) /* 提交vuex状态改变 */
                    }
                  }, 1000);
                })
                .catch(errorResult => {
                  loading.close();
                  this.isSendCode = false;
                  message.error({
                    message: errorResult,
                    duration: 2000,
                    offset: 80
                  })
                });
      })
              .catch(errorResult => {
                //验证码错误，刷新
                loading.close();
                message.error({
                  message: errorResult,
                  duration: 2000,
                  offset: 80
                })
                this.refreshCode();

              });


    },
    goBack() {
      this.$router.push({ path: "/login" });
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  padding-top: 20px;
  .tit {
    margin-bottom: 50px;
    overflow: hidden;
    h3 {
      float: left;
      font-size: 24px;
      color: #000;
    }
    .wei {
      float: right;
      line-height: 30px;
      color: #7e7e7e;
      font-size: 14px;
      cursor: pointer;
      .iconfont {
        margin-right: 10px;
        color: #5acd54;
        font-size: 16px;
      }
    }
  }
}
</style>
